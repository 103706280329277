// src/components/CancerfondenAdvert.js
import { Container, Box, Typography, Button, Grid } from '@mui/material';
import { getImageUrl } from '../../utils';

const CancerfondenAdvert = () => (
  <Container 
    sx={{ 
      padding: { xs: '1.5rem', md: '3rem' },
      backgroundColor: '#f5f5f5',
      borderRadius: '16px', 
      boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.15)', 
      maxWidth: '1000px',
      margin: '1rem auto',
      position: 'relative',
    }}
  >
    {/* Pink Logo */}
    <Box
      sx={{
        position: 'absolute',
        top: '20px',
        left: '20px',
        zIndex: 1,
      }}
    >
      <img 
        src={getImageUrl('pink-logo.png')} 
        alt="Pink Logo"
        style={{
          maxWidth: '16rem', // Smaller logo for mobile screens
          height: 'auto',
        }}
      />
    </Box>

    {/* Main Content */}
    <Grid 
      container 
      spacing={4} 
      alignItems="center" 
      direction={{ xs: 'column', md: 'row' }} // Stacks vertically on small screens
    >
      {/* Right-Side Image */}
      <Grid 
        item 
        xs={12} 
        md={4} 
        order={{ xs: 1, md: 2 }} 
        sx={{
          marginTop: { xs: '6rem', md: 0 }, // Adds space on small screens
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img 
            src={getImageUrl('birgitta.png')} 
            alt="Birgitta Möller"
            style={{
              width: '100%',
              maxWidth: { xs: '150px', md: '200px' }, // Smaller image for mobile screens
              borderRadius: '12px',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          />
        </Box>
      </Grid>

      {/* Content Area */}
      <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
        {/* Header */}
        <Typography 
          variant="h5" // Reduced font size for mobile
          sx={{  
            paddingX: { xs: '0.5rem', md: '2rem' },
            textAlign: 'center',
            fontWeight: 'bold', 
            marginBottom: '1rem', 
            fontSize: { xs: '1.25rem', md: '2rem' },
            marginTop: { xs: '1rem', md: '4rem' },
          }}
        >
          Insamling till Cancerfonden
        </Typography>

        {/* Subheader */}
        <Typography 
          variant="body1" 
          sx={{ 
            textAlign: 'justify',
            color: '#444', 
            fontWeight: '600', 
            marginBottom: '1rem', 
            fontSize: { xs: '0.9rem', md: '1.1rem' },
            paddingX: { xs: '1rem', md: '3rem' }, // Adds space on both sides
          }}
        >
          Pilates Höganäs samlar in pengar till Cancerfonden i minne av Birgitta Möller
        </Typography>

        {/* Description */}
        <Typography 
          variant="body2" 
          sx={{ 
            color: '#444', 
            lineHeight: 1.6, 
            fontSize: { xs: '0.85rem', md: '1rem' },
            marginBottom: '1.5rem', 
            textAlign: 'justify',
            paddingX: { xs: '1rem', md: '3rem' },
          }}
        >
          Den <strong>15 februari</strong> arrangerar vi en insamlingsdag på studion 
          i minne av älskade <strong>Birgitta Möller</strong>, som somnade in <strong>26 december 2024</strong>. 
          Hela dagen kommer vara fylld med träning, samvaro, och möjligheter att stödja Cancerfondens arbete. 
          <strong>Mer information kommer snart!</strong>
          <br /><br />
          Din hjälp gör skillnad.
        </Typography>

        {/* Buttons */}
        <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#444',
              color: '#fff',
              padding: { xs: '0.5rem 1.5rem', md: '0.75rem 2rem' },
              borderRadius: '12px',
              fontWeight: '400',
              textTransform: 'none',
              fontSize: { xs: '0.8rem', md: '0.9rem' },
              '&:hover': {
                backgroundColor: '#7c7c7c',
              },
            }}
            href="https://www.cancerfonden.se/insamlingar/pilates-hoganas-samlar-in-pengar-till-cancerfonden-i-minne-av-birgitta-moller?fbclid=IwY2xjawH48xxleHRuA2FlbQIxMAABHZO--jErv0tXB8E3wz9RT_gUsl0UZhWp31XorZiwDd375GWzRKPBPSGOBg_aem_1hreD7hOmsDaggXGfCwq3A"
            target="_blank"
            rel="noopener noreferrer" 
          >
            Insamlingen
          </Button>

          <Button
            variant="contained"
            sx={{
              backgroundColor: '#DD0D7D',
              color: '#fff',
              padding: { xs: '0.5rem 1rem', md: '0.75rem 2rem' },
              borderRadius: '12px',
              fontWeight: '600',
              textTransform: 'none',
              fontSize: { xs: '0.8rem', md: '1rem' },
              '&:hover': {
                backgroundColor: '#E98FBF',
              },
            }}
            href="https://www.cancerfonden.se/stod-oss/skank-till-en-insamling/pilates-hoganas-samlar-in-pengar-till-cancerfonden-i-minne-av-birgitta-moller"
            target="_blank"
            rel="noopener noreferrer" 
          >
            Bidra till Insamlingen
          </Button>
        </Box>
      </Grid>
    </Grid>
  </Container>
);

export default CancerfondenAdvert;
