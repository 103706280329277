// src/components/Schedule.js
import { Container, Typography, Box, Button, Link } from '@mui/material';
import { getImageUrl } from '../../utils';
import EventAvailableIcon from '@mui/icons-material/EventAvailable'; // Icon for "ställer in klasser"
import SafetyDividerIcon from '@mui/icons-material/SafetyDivider'; // Icon for "Reformer klasser"
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'; // Icon for "Osäkra på klasser"
import InfoIcon from '@mui/icons-material/Info'; // Icon for "Info & Regler"

const Schema = () => (
  <Container sx={{ padding: '3rem 0', backgroundColor: '#f9f9f9' }}>
    <Typography 
      variant="h4" 
      align="center" 
      sx={{ 
        fontWeight: 'bold', 
        color: '#333', 
        marginBottom: '2rem',
        fontSize: { xs: '2rem', md: '2.5rem' },
      }}
    >
      Schema
    </Typography>

    <Box
      sx={{
        overflowX: 'auto',
        overflowY: 'hidden',
        boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)',
        borderRadius: '12px',
        maxWidth: '900px',
        height: '50rem',
        margin: '0 auto',
        padding: { xs: '1rem', md: '2rem' },
        backgroundColor: '#fff',
      }}
    >
      <iframe
        id="wondr-2"
        src="https://PILATESHOGANAS.wondr.se/schema/"
        width="100%"
        height="560px"
        frameBorder="0"
        style={{
          border: 'none',
          overflowX:'auto',
          overflowY:'hidden',
          borderRadius: '12px',
          width: '100%',
          height: '100%',
        }}
        title="Wondr Booking"
      ></iframe>
    </Box>

    <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '2rem', marginTop:'2rem' }}>
      <Button
        sx={{
          backgroundColor: '#00b5bf',
          color: '#fff',
          padding: '0.5rem 1rem',
          fontSize: '1rem',
          fontWeight: '600',
          borderRadius: '12px',
          textTransform: 'none',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          '&:hover': {
            backgroundColor: '#A2557C',
          },
        }}
        href="/sidepages/membership-info"
      >
        Alla Klasser
      </Button>
    </Box>

    <Box sx={{ 
      display: 'flex', 
      flexDirection: { xs: 'column', sm: 'row' }, 
      alignItems: 'center', 
      justifyContent: 'center', 
      marginBottom: '2rem',
      textAlign: 'center'
    }}>
      <Typography variant="body1" sx={{ fontSize: '1rem', maxWidth: '15rem', marginBottom: { xs: '1rem', sm: '0' }, marginRight: { sm: '1rem' } }}>
        Observera att alla deltagare måste bära strumpor under klasserna. <br/><br/> Klart har vi strumpor till försäljning i olika prisklasser!
      </Typography>
      <img
        src={getImageUrl('new-about2.png')}
        alt="Socks"
        style={{
          width: '100%',
          maxWidth: '12rem',
          height: 'auto',
          borderRadius: '12px',
          marginLeft: { xs: '0', sm: '1rem' },
        }}
      />
    </Box>
     

    {/* Additional information section with icons */}
    <Box sx={{ marginTop: '3rem' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
        <EventAvailableIcon sx={{ color: '#00b5bf', marginRight: '0.5rem' }} />
        <Typography variant="body2" sx={{ fontSize: '1rem', fontStyle: 'italic' }}>
          Vi förbehåller oss rätten att ställa in klasser vid för få deltagare.
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
        <SafetyDividerIcon sx={{ color: '#00b5bf', marginRight: '0.5rem' }} />
        <Typography variant="body2" sx={{ fontSize: '1rem', fontStyle: 'italic' }}>
          Reformer klasser är för dem som gått intro Reformer eller tränar PT och har kunskap om ”safety” på Reformern.
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
        <HelpOutlineIcon sx={{ color: '#00b5bf', marginRight: '0.5rem' }} />
        <Typography variant="body2" sx={{ fontSize: '1rem', fontStyle: 'italic' }}>
          Blir ni osäkra vilka klasser ni ska välja, gå in och kolla klassbeskrivning eller fråga er instruktör. Vi finns här för er skull.
        </Typography>
      </Box>

      

      {/* "Se mer Info & Regler" link with an icon */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
        <Link
          href="/sidepages/info-regler"
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: '#00b5bf',
            fontWeight: '600',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          <InfoIcon sx={{ marginRight: '0.5rem' }} />
          Mer info som är bra för dig att veta: klicka här
        </Link>
      </Box>
    </Box>
  </Container>
);

export default Schema;
